/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import QuickTable from "../../../../../../components/QuickTable";
import { bigToUsd, toFixedMax } from "../../../../payrollTools";
import { closestDateBeforeOrOnDayIndex, usdFormatter, validateDecimalFixed } from "../../../../../../tools";
import moment from "moment";
import Big from "big.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export default function PayrollEmployeeWagesEditor({entry, payrollPeriodStartDay}) {
    
    const weekElements = entry.weeks.map((week, index) => {
        const trueWeekStart = moment(closestDateBeforeOrOnDayIndex(entry.periodStart, payrollPeriodStartDay)).add(index, 'weeks').format('YYYY-MM-DD');
        const trueWeekEnd = moment(trueWeekStart).add(6, 'days').format('YYYY-MM-DD');
        const weekStart = trueWeekStart < entry.periodStart ? entry.periodStart : trueWeekStart;
        const weekEnd = trueWeekEnd > entry.periodEnd ? entry.periodEnd : trueWeekEnd;


        const payElements = week.pay.map((pay) => {
            return (
                <tr key={pay.id}>
                    <td>{pay.payType == 0 ? 'Hourly' : parseInt(pay.payType) > 0 ? 'Salary' : 'Terminated'}</td>
                    <td>{`${moment(pay.payStart).format('MMM D')} - ${moment(pay.payEnd).format('MMM D')}`}</td>
                    <td>{parseInt(pay.payType) >= 0 ? usdFormatter.format(pay.payRate) : 'N/A'}</td>
                    <td>{pay.payType == 0 ? toFixedMax(pay.unitsWorked, 3) : 'N/A'}</td>
                    <td>{bigToUsd(pay.getWages())}</td>
                </tr>
            )
        });

        return (week.qualifiesForFLSA() ? 
            <React.Fragment key={index}>
                <tr>
                    <td>Hourly</td>
                    <td>{`${moment(weekStart).format('MMM D')} - ${moment(weekEnd).format('MMM D')}`}</td>
                    <td>{bigToUsd(week.hourlyRate())}</td>
                    <td>{week.straightTimeHoursWorked().toNumber()}</td>
                    <td>{bigToUsd(week.hourlyWages())}</td>
                </tr>
                <tr>
                    <td>Overtime</td>
                    <td>{`${moment(weekStart).format('MMM D')} - ${moment(weekEnd).format('MMM D')}`}</td>
                    <td>
                        <span>{bigToUsd(week.overtimeRate())}</span>

                        <OverlayTrigger overlay={
                            <Popover style={{maxWidth: 600}}>
                                <Popover.Header>Overtime Rate Calculation</Popover.Header>
                                <Popover.Body>
                                    <p>
                                        Nondiscretionary bonuses are incorporated into the overtime pay rate by dividing the bonus pay by the number of hours worked and adding it to the hourly wage.
                                    </p>
                                    <p style={{marginBottom: 0}}>{'((hourly wage + (nondiscretionary bonuses  ÷ hours worked)) ÷ 2.0) + hourly wage'}</p>
                                    <FontAwesomeIcon icon={faArrowRight} style={{marginLeft: 12, marginRight: 12}}/>
                                    <span>{'( (' + bigToUsd(week.hourlyRate()) + ' + (' + bigToUsd(week.getNDBonuses())  + ' ÷ ' + week.hoursWorked + ') ) ÷ 2.0 ) + ' + bigToUsd(week.hourlyRate())  + ' = '}</span>
                                    <span style={{fontWeight: 'bold'}}>{bigToUsd(week.overtimeRate())}</span>
                                </Popover.Body>
                            </Popover>
                        }>
                            <FontAwesomeIcon style={{color: 'var(--bs-primary)', marginLeft: 6}} icon={faQuestionCircle}/>
                        </OverlayTrigger>
                    </td>
                    <td>{week.overtimeHoursWorked().toFixed(2)}</td>
                    <td>{bigToUsd(week.overtimeWages())}</td>
                </tr>
            </React.Fragment>
        :
            <React.Fragment key={index}>
                {payElements}
            </React.Fragment>  
        )
    })

    const total = entry.weeks.reduce((prev, curr) => {
        return prev.plus(curr.payWages());
    }, new Big(0.0))

    const totalRow = (
        <tr key={'totals'} style={{fontWeight: 'bold'}}>
            <td colSpan={4}>Total Wages</td>
            <td>{bigToUsd(total)}</td>
        </tr>
    )
  
    return (
        <QuickTable title='Wages' headers={['Pay Type', 'Date Range', 'Pay Rate', 'Units Worked', 'Subgross']} rows={weekElements.concat(totalRow)}/>
    )
}



