/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import RadioControl from './RadioControl';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { Button, FloatingLabel, Form } from 'react-bootstrap';

export default function DateRangeSelector({show, onHide, centered, size, handleSetDateRange, presetOptions}) {

    const [selectedPreset, setSelectedPreset] = useState('');
    const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');

    const presetNameDictionary = {
        pastYear: 'Past Year', 
        pastMonth: 'Past Month', 
        pastWeek: 'Past Week', 
        nextWeek: 'Next Week', 
        nextMonth: 'Next Month', 
        nextYear: 'Next Year',
        allTime: 'All Time',
        yesterday: 'Yesterday',
        custom: 'Custom Range',
    }
    
    const options = ['custom'];

    presetOptions.forEach((key) => {
        if (key in presetNameDictionary) {
            options.unshift(key);
        }
    });


    const handleSelectPreset = () => {
        switch (selectedPreset) {
            case 'pastYear':
                handleSetDateRange(moment().subtract(1, 'year').add(1, 'day').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
                break;
            case 'pastMonth':
                handleSetDateRange(moment().subtract(1, 'month').add(1, 'day').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
                break;
            case 'pastWeek':
                handleSetDateRange(moment().subtract(1, 'week').add(1, 'day').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
                break;
            case 'nextWeek':
                handleSetDateRange(moment().format('YYYY-MM-DD'), moment().add(1, 'week').subtract(1, 'day').format('YYYY-MM-DD'));
                break;
            case 'nextMonth':
                handleSetDateRange(moment().format('YYYY-MM-DD'), moment().add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD'));
                break;
            case 'nextYear':
                handleSetDateRange(moment().format('YYYY-MM-DD'), moment().add(1, 'year').subtract(1, 'day').format('YYYY-MM-DD'));
                break;  
            case 'allTime':
                handleSetDateRange('', '');
                break;  
            case 'custom':
                handleSetDateRange(customStart ? moment(customStart).format('YYYY-MM-DD') : '', customEnd ? moment(customEnd).format('YYYY-MM-DD') : '');
                break;
            case 'yesterday':
                handleSetDateRange(moment().subtract(1, 'day').format('YYYY-MM-DD'), moment().subtract(1, 'day').format('YYYY-MM-DD'));
                break;      
            default:
                break;              
        }
        onHide();
    }


    return (
        <Modal show={show} onHide={onHide} centered={centered} size={size}>
            <Modal.Header closeButton>
                <Modal.Title>Select Date Range</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RadioControl inline selection={selectedPreset} setSelection={setSelectedPreset} optionNames={options.map(o => presetNameDictionary[o])} optionValues={options}/>
                { selectedPreset === 'custom' ?
                    <div style={{display: 'flex', marginTop: 12, gap: 12}}>
                        <FloatingLabel label='Start Date' style={{flex: 1}}>
                            <Form.Control type='date' max={customEnd} placeholder=' ' value={customStart} onChange={(event) => {setCustomStart(event.target.value)}}/> 
                        </FloatingLabel>
                        <FloatingLabel label='End Date' style={{flex: 1}}>
                            <Form.Control type='date' min={customStart} placeholder=' ' value={customEnd} onChange={(event) => {setCustomEnd(event.target.value)}}/> 
                        </FloatingLabel>
                    </div>
                : ''}
            </Modal.Body>
            <Modal.Footer>
                <Button variant={'outline-primary'}  onClick={handleSelectPreset}>Submit</Button>
            </Modal.Footer>
        </Modal>
    )
}