/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './CustomDatePicker.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useState } from "react";
import { Button, ButtonGroup, FloatingLabel, Form } from "react-bootstrap";

export default function CustomDatePicker({startDate, setStartDate, endDate, setEndDate, title, disabledDates = [], minDate, maxDate, validator}) {
    const [show, setShow] = useState(false);

    return (
        <FloatingLabel label={title} style={{position: 'relative'}} onClick={() => {setShow(!show)}}>
            <Form.Select className='cursor-pointer' isInvalid={validator && !validator.isValid()} isValid={validator && validator.isValid()}/>
            { validator && <Form.Control.Feedback type='invalid'>{validator.getInvalidMessage()}</Form.Control.Feedback>}
            { validator && <Form.Control.Feedback type='valid'>{validator.getValidMessage()}</Form.Control.Feedback>}
            <span className='cursor-pointer' style={{position: 'absolute', top: 25, left: 12, opacity: !startDate || !endDate ? 0.5 : 1, fontStyle: !startDate || !endDate ? 'italic' : 'normal'}}>{!startDate || !endDate ? 'No Selection' : `${moment(startDate).format('MM/DD/YYYY')} - ${moment(endDate).format('MM/DD/YYYY')}`}</span>
            { show &&
                <CustomDatePickerModal 
                    startDate={startDate} 
                    setStartDate={setStartDate} 
                    endDate={endDate} 
                    setEndDate={setEndDate} 
                    disabledDates={disabledDates} 
                    minDate={minDate} 
                    maxDate={maxDate} 
                    hide={() => {setShow(false)}}
                />
            }
        </FloatingLabel>
    )
}

function CustomDatePickerModal({startDate, setStartDate, endDate, setEndDate, disabledDates, minDate, maxDate, hide}) {
    const [selectedMonth, setSelectedMonth] = useState(startDate ? startDate : moment().format('YYYY-MM-DD'));
    const [hovering, setHovering] = useState(undefined);
    const startOfMonth = moment(selectedMonth).startOf('month');
    const startOfWeek = moment(startOfMonth).startOf('week');
    
    const weeks = [0, 1, 2, 3, 4, 5].map((weekIndex) => {
        const days = [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
            const day = moment(startOfWeek).add(weekIndex, 'weeks').add(dayIndex, 'days');
            const dayFormatted = day.format('YYYY-MM-DD');
            const isToday = dayFormatted == moment().format('YYYY-MM-DD');
            const isDisabled = disabledDates.includes(dayFormatted) || (minDate && dayFormatted < minDate) || (maxDate && dayFormatted > maxDate);
            const isSelected = endDate ? (dayFormatted >= startDate && dayFormatted <= endDate) : dayFormatted == startDate;
            const isOutsideMonth = day.format('MM') !== moment(selectedMonth).format('MM');

            const rangeContainsDisabled = disabledDates.find(d => (d < startDate && d > hovering) || (d > startDate && d < hovering));
            const isInRange = !endDate && !rangeContainsDisabled && dayFormatted !== hovering && dayFormatted !== startDate && (dayFormatted > startDate && dayFormatted < hovering);

            return (
                <div className={`day ${isOutsideMonth ? 'day-outside' : ''} ${isInRange ? 'day-in-range' : ''} ${isDisabled ? 'day-disabled' : ''} ${isToday ? 'day-today' : ''} ${isSelected ? 'day-selected' : ''}`} key={dayIndex} onClick={() => {
                    if (!isDisabled) {
                        if (startDate && !endDate && startDate <= dayFormatted && !rangeContainsDisabled) {
                            setEndDate(dayFormatted);
                        } else {
                            setStartDate(dayFormatted);
                            setEndDate(undefined);
                        }
                    }
                }} onMouseEnter={() => {
                    if (!isDisabled) {
                        setHovering(dayFormatted);
                    }
                }}>
                    <span>{day.format('D')}</span>
                </div>
            )
        })

        return (
            <div key={weekIndex} style={{display: 'flex'}}>
                {days}
            </div>
        )
    })

    const headers = [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
        return (
            <div key={dayIndex} style={{width: 30, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', userSelect: 'none'}}>
                <span>{moment(startOfWeek).add(dayIndex, 'days').format('dd')}</span>
            </div>
        )
    })

    return (
        <>
            <div style={{zIndex: 1, position: 'fixed', top: 0, right: 0, bottom: 0, left: 0}}/>
            <div style={{zIndex: 2, backgroundColor: 'white', border: '1px solid lightgray', borderRadius: 6, position: 'absolute', top: 58, left: 0, padding: 16}} onClick={(event) => {event.stopPropagation()}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 4px 0px 4px'}}>
                    <span style={{fontWeight: 'bold', userSelect: 'none'}}>{moment(selectedMonth).format('MMM YYYY')}</span>
                    <ButtonGroup>
                        <Button style={{padding: '0px 10px 0px 10px', color: 'white'}} onClick={() => {
                            setSelectedMonth(moment(selectedMonth).subtract(1, 'month').format('YYYY-MM-DD'));
                        }}><FontAwesomeIcon size='sm' icon={faChevronLeft}/></Button>
                        <Button style={{padding: '0px 10px 0px 10px', color: 'white'}} onClick={() => {
                            setSelectedMonth(moment(selectedMonth).add(1, 'month').format('YYYY-MM-DD'));
                        }}><FontAwesomeIcon size='sm' icon={faChevronRight}/></Button>
                    </ButtonGroup>
                </div>
                <div style={{display: 'flex'}}>
                    {headers}
                </div>
                {weeks}
                <div style={{userSelect: 'none', display: 'flex', justifyContent: 'space-between'}}>
                    <span className='cursor-pointer' style={{color: 'var(--bs-primary)', fontSize: 14}} onClick={() => {setStartDate(undefined); setEndDate(undefined);}}>
                        Clear Selection
                    </span>
                    <span className='cursor-pointer' style={{color: 'var(--bs-primary)', fontSize: 14}} onClick={() => {setSelectedMonth(moment().format('YYYY-MM-DD'))}}>
                        Today
                    </span>
                </div>
            </div>
        </>
    )
}