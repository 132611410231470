/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/


function NavigationBarHeader({title}) {
    return (
        <div style={{display: 'flex', alignItems: 'center', gap: 8, width: '100%', justifyContent: 'center', color: 'gray'}}>
            <div style={{backgroundColor: 'gray', height: 1, flex: 1, marginLeft: 12}}/>
            <h6 style={{margin: 0}}>{title}</h6>
            <div style={{backgroundColor: 'gray', height: 1, flex: 1, marginRight: 12}}/>
        </div>
    );
}

export default NavigationBarHeader;