/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/


import React from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Dropdown from 'react-bootstrap/Dropdown';

export default function SearchBar({label, value, setValue, typeNames, typeValues, type, setType}) {

    const dropdownItems = typeValues ? typeValues.map((typeValue, index) => {
        return (
            <Dropdown.Item key={typeValue} eventKey={typeValue} active={type === typeValue} onClick={() => {setType(typeValue)}}>{typeNames[index]}</Dropdown.Item>
        )
    }) : '';

    const currentFilter = typeValues ? typeNames[typeValues.indexOf(type)] : '';

    return (
        <InputGroup style={{flexWrap: 'nowrap', minWidth: 180}}>
            <InputGroup.Text><FontAwesomeIcon icon={faSearch} style={{color: 'black'}}/></InputGroup.Text>
            <Form.Control type='text' value={value} placeholder={label} onChange={(event) => {setValue(event.target.value)}}/>
            { typeValues ?
                <Dropdown>
                    <Dropdown.Toggle variant={'outline-primary'}>
                        {currentFilter ? currentFilter : ' '}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {dropdownItems}
                    </Dropdown.Menu>
                </Dropdown>
            : ''}
        </InputGroup>
    )
}