/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// import { generatePayrollCSV } from "./payrollTools";
import PayrollPeriodCsv from "./PayrollPeriod/PayrollPeriodCsv";

export default function PayrollCSVDownloadModal({entries, callBack, periodStart, periodEnd, companyName}) {

    const downloadCsv = () => {
        const str = PayrollPeriodCsv(entries, companyName, periodStart, periodEnd);

        const blob = new Blob([str], { type: 'text/csv;charset=utf-8,' });
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = companyName + ' ' + moment(periodStart).format('MM-DD-YYYY') + ' - ' + moment(periodEnd).format('MM-DD-YYYY') + ' Payroll.csv';
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }

    return (
        <>
            <Modal.Header>
                <Modal.Title>CSV Spreadsheet Download</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Click the download button to download a spreadsheet of the approved payroll period.
            </Modal.Body>
            <Modal.Footer>
                <Button style={{color: 'white'}} onClick={downloadCsv}>Download</Button>
                <Button style={{color: 'white'}} onClick={callBack}>Dismiss</Button> 
            </Modal.Footer>
        </>
    )
}