/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import CustomButton from "./CustomButton";

//callback must be async
//callback must return error string if something goes wrong, else it must return falsy

export default function APIModal({show, onHide, centered, size, callBack, title, message, buttonLabel}) {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(undefined);

    const handleCallBack = async () => {
        setIsLoading(true);
        setError(false);
        const error = await callBack();
        if (error) {
            setError(error);
        } else {
            onHide();
        }
        setIsLoading(false)
    }

    return (
        <Modal show={show} onHide={onHide} centered={centered} size={size}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            { message &&
                <Modal.Body>
                    {message}
                </Modal.Body>
            }
            <Modal.Footer>
                { error &&
                    <span style={{color: 'red'}}>{error}</span>
                }
                <CustomButton isLoading={isLoading} label={buttonLabel} onClick={handleCallBack}/>
            </Modal.Footer>
        </Modal>
    )
}