/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Col from "react-bootstrap/Col";
import InputGroup from 'react-bootstrap/InputGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';


export default function PayrollMoneyCustomControl({label, value, onChange, xs, sm, md, lg, xl, xxl, readOnly, disabled = false}) {
    return (
        <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} style={{marginBottom: 8}}>
            <InputGroup>
                <InputGroup.Text>$</InputGroup.Text>
                <FloatingLabel label={label}>
                    <Form.Control 
                        disabled={disabled}
                        type='number' 
                        value={value} 
                        onChange={onChange}
                        placeholder=' '
                        readOnly={readOnly}
                    />
                </FloatingLabel>
            </InputGroup>
        </Col>
    )
}