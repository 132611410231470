/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import Button from 'react-bootstrap/Button';
import PayrollCSADownloadModal from "../PayrollCSVDownloadModal";
import PayrollPeriodTable from "../PayrollPeriod/PayrollPeriodTable";
import PayrollPeriodPdf from "../PayrollPeriod/PayrollPeriodPdf";

export default function PayrollPeriodPreview({entries, periodStart, periodEnd, companyName}) {
    const [showCsvModal, setShowCsvModal] = useState(false);
    const [showPdfModal, setShowPdfModal] = useState(false);

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>
                    {`Payroll for ${companyName} Between ${moment(periodStart).format('MMM D, YYYY')} - ${moment(periodEnd).format('MMM D, YYYY')}`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PayrollPeriodTable entries={entries} originalEntries={undefined} periodStart={periodStart} periodEnd={periodEnd}/> 
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={() => {setShowCsvModal(true)}}>Download Payroll CSV</Button>
                <Button variant="outline-primary" onClick={() => {setShowPdfModal(true)}}>View Payroll PDF</Button>
            </Modal.Footer>
            <Modal show={showCsvModal} onHide={() => {setShowCsvModal(false)}} centered>
                <PayrollCSADownloadModal 
                    entries={entries} 
                    callBack={() => {setShowCsvModal(false)}}
                    periodStart={periodStart} 
                    periodEnd={periodEnd} 
                    companyName={companyName}
                />
            </Modal>
            <Modal show={showPdfModal} onHide={() => {setShowPdfModal(false)}} fullscreen>
                <PayrollPeriodPdf 
                    entries={entries}
                    originalEntries={undefined}
                    callBack={() => {setShowPdfModal(false)}}
                    periodStart={periodStart} 
                    periodEnd={periodEnd} 
                    companyName={companyName}
                />
            </Modal>
        </>
    )
}