/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import moment from "moment";

export default class PayrollEditorSavedTime extends React.Component{

    constructor(props){
        super(props);
    }


    componentDidMount() {
        this.timerID = setInterval(
            () => {this.forceUpdate()},
            60000
        );
    }
    
    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    timeSinceDate(date) {
      
        const minutes = moment().diff(moment(date), 'minutes');
       
        let array = [];
    
        if (minutes > 0) {
            return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`;
        } else {
            return 'less than a minute';
        }
        
    }

    render(){

        return (
            <span style={{fontStyle:'italic', opacity:0.5}}>{'Saved ' + this.timeSinceDate(this.props.timeStamp) + ' ago'}</span>
        )
    }
}