/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { wrapElementInCol } from '../tools';

function Picker({selection, setSelection, itemNames, itemValues, title, validator, xs, sm, md, lg, xl, xxl}) {
    const [touched, setTouched] = useState(false);
    
    const getActiveName = () => {
        for (let i = 0; i < itemValues.length; i++) {
            if (itemValues[i] == selection) {
                return itemNames[i];
            }
        }
        return '';
    }
    
    const dropdownItems = itemValues.map((value, index) => {
        return (
            <Dropdown.Item key={value} active={selection == itemValues[index]} onClick={() => {setSelection(value); setTouched(true)}}>{itemNames[index]}</Dropdown.Item>
        )
    });

    const content = (
        <div>
            <Dropdown as={InputGroup}>
                <FloatingLabel label={title}>
                    <Form.Control 
                        disabled 
                        value={getActiveName()} 
                        style={{backgroundColor: 'white'}} 
                        isInvalid={validator && !validator.isValid()} 
                        isValid={validator && validator.isValid()} 
                    />
                </FloatingLabel>
                <Dropdown.Toggle variant={'outline-primary'}/>
                <Dropdown.Menu>
                    {dropdownItems}
                </Dropdown.Menu>
            </Dropdown>
            { validator && 
                <span style={{fontSize: '0.875em', color: validator.isValid() ? 'green' : 'red'}}>{validator.isValid() ? validator.getValidMessage() : validator.getInvalidMessage()}</span>
            }
        </div>
    )

    return (
        <>
            {xs || sm || md || lg || xl || xxl ? wrapElementInCol(content, {xs: xs, sm: sm, md: md, lg: lg, xl: xl, xxl: xxl}) : content}
        </>
    )
}

export default Picker;